import type { GetStaticProps } from 'next'
import { PAGE_QUERY } from '@/lib/api/queries'
import { IContent, IMenuRecord, IPage, ISite } from '@/lib/global.types'
import PageContent from '@/components/helpers/PageContent'
import client from '@/lib/api/apollo-client'
import Layout from '@/components/helpers/Layout'
import Head from 'next/head'
import { renderMetaTags } from 'react-datocms'

interface IHomeProps {
  page: IPage
  mainMenu: IMenuRecord
  footerMenu: IMenuRecord
  socialMediaMenu: IMenuRecord
  site: ISite
}

export default function Home({ page, mainMenu, footerMenu, socialMediaMenu, site }: IHomeProps) {
  return (
    <Layout mainMenu={mainMenu} footerMenu={footerMenu} socialMediaMenu={socialMediaMenu}>
      <Head>
        {renderMetaTags(page.seo.concat(site.favicon))}
        <meta property="og:url" content={`https://wrobeldesign.com`} />
      </Head>
      {page.content && page.content.map((block: IContent) => PageContent(block))}
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async (context) => {
  const { locale } = context

  const { data } = await client.query({
    query: PAGE_QUERY,
    variables: {
      locale,
      slug: '',
    },
  })

  if (!data?.page) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      page: data.page,
      mainMenu: data?.mainMenu,
      footerMenu: data?.footerMenu,
      socialMediaMenu: data?.socialMediaMenu,
      site: data?.site,
    },
    revalidate: 1,
  }
}
